import "react-phone-number-input/style.css";
import { useRouter } from "next/router";
import PhoneInput from "react-phone-number-input";

import { useToast } from "../../context/toast";
import { classNames } from "../../utils/formatters";

export default function CollectionSubscriptionForm({
  vehicleId,
  slug,
}: {
  vehicleId: string;
  slug?: string;
}) {
  const router = useRouter();
  const { toggleToast } = useToast();

  async function submit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("interested_in", vehicleId ? "vehicle" : "service");
    formData.append("url", window.location.href);
    const body = Object.fromEntries(formData.entries());

    await fetch("/api/email/collection-signup", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((r) => {
      const response = r.json();

      // @ts-ignore
      if (r.status >= 300) {
        toggleToast({
          open: true,
          title: "Tuvimos un problema",
          description:
            "Hubo un error al subscribirte a Collection by Movi. Intentalo de nuevo más tarde.",
          type: "error",
        });
      } else {
        toggleToast({
          open: true,
          title: "¡Gracias por dejarnos tu información!",
          description: `Te contactaremos pronto con más información de ${
            vehicleId ? "este auto" : "Collection by Movi"
          }.`,
          type: "success",
        });

        router.push(slug ? `/autosusados/${slug}` : "/collection");
      }
      return response;
    });
  }

  return (
    <form className="w-full max-w-md lg:col-span-5 lg:pt-2" onSubmit={submit}>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-auto gap-x-4">
          <label htmlFor="first_name" className="sr-only">
            Nombre
          </label>
          <input
            id="first_name"
            name="first_name"
            type="text"
            autoComplete="off"
            required
            className="min-w-0 w-1/2 rounded-md border-0 bg-white/5 px-3.5 py-2 text-white placeholder-neutral-300 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 text-input leading-6"
            placeholder="Nombre"
          />
          <label htmlFor="last_name" className="sr-only">
            Apellido
          </label>
          <input
            id="last_name"
            name="last_name"
            type="text"
            autoComplete="off"
            required
            className="min-w-0 w-1/2 rounded-md border-0 bg-white/5 px-3.5 py-2 text-white placeholder-neutral-300 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 text-input leading-6"
            placeholder="Apellido"
          />
        </div>
        <div className="flex-auto">
          <label htmlFor="email" className="sr-only">
            Correo electrónico
          </label>
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="min-w-0 w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white placeholder-neutral-300 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 text-input leading-6"
            placeholder="Correo electrónico"
          />
        </div>
        <div className="flex-auto">
          <label htmlFor="phone_number" className="sr-only">
            Número telefónico
          </label>
          <PhoneInput
            // value={value}
            onChange={() => null}
            defaultCountry="CR"
            placeholder="Número telefónico"
            id="phone_number"
            name="phone_number"
            autoComplete="tel"
            required
            // className={`${
            //   errors.primaryPhone
            //     ? "ring-2 ring-red-500 ring-offset-2 ring-offset-white"
            //     : ""
            // } phone-number-input`}
            className={classNames(`phone-number-input collection-form`)}
          />
        </div>
        <button
          type="submit"
          className="flex-none rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
        >
          Enviar
        </button>
      </div>
      <p className="mt-4 text-sm leading-6 text-gray-300">
        Nos importa resguardar su información. Ver{" "}
        <a href="/privacy" className="font-semibold text-white" target="_blank">
          políticas&nbsp;de&nbsp;privacidad
        </a>
        .
      </p>
    </form>
  );
}
