import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { classNames } from "../utils/formatters";
import useRangeFilter from "../hooks/useRangeFilter";
import { useRouter } from "next/router";
import { CURRENCIES, CURRENCY_KEYS } from "../utils/constants";
import CurrencyFilter from "./CurrencyFilter";

export default function PriceFilter() {
  const router = useRouter();
  const queryObject = router.query;
  const { currency } = queryObject;

  const { min, max, setFilterValue, submitRangeFilter } = useRangeFilter([
    "priceMin",
    "priceMax",
  ]);

  function submit(e) {
    e.preventDefault();
    submitRangeFilter();
  }

  return (
    <div className="py-4">
      <div className="flex justify-between">
        <h3 className="font-medium text-gray-900">Precio</h3>
        <CurrencyFilter />
      </div>
      <form className="flex items-end" onSubmit={submit}>
        <div className="flex w-full">
          <div className="w-1/2">
            <label
              htmlFor="priceMin"
              className="min-w-0  text-gray-500 text-sm"
            >
              Min.{" "}
              <span>
                (
                {CURRENCIES[currency as string]?.symbol ||
                  CURRENCIES[CURRENCY_KEYS.CRC].symbol}
                )
              </span>
            </label>
            <input
              id="priceMin"
              name="min"
              defaultValue={min}
              type="number"
              className=" border-gray-300 rounded-l w-full focus:ring-orange-500 focus:border-orange-500 text-input"
              onChange={setFilterValue}
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="priceMax"
              className="min-w-0  text-gray-500 text-sm"
            >
              Max.{" "}
              <span>
                (
                {CURRENCIES[currency as string]?.symbol ||
                  CURRENCIES[CURRENCY_KEYS.CRC].symbol}
                )
              </span>
            </label>
            <input
              id="priceMax"
              name="max"
              defaultValue={max}
              type="number"
              className=" border-gray-300 border-l-0 w-full focus:ring-orange-500 focus:border-orange-500 text-input"
              onChange={setFilterValue}
            />
          </div>
        </div>
        <button
          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-orange-500 font-medium rounded-r-md text-white bg-orange-500 hover:bg-orange-600 hover:border-orange-600 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500 text-sm"
          type="submit"
        >
          <MagnifyingGlassIcon
            className={classNames("text-white h-5 w-5")}
            aria-hidden="true"
          />
          <span className="inline sr-only">Buscar</span>
        </button>
      </form>
    </div>
  );
}
