import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function useRangeFilter(keys: string[]) {
  const router = useRouter();
  const queryObject = router.query;
  const [minKey, maxKey] = keys;
  const minValue = queryObject[minKey];
  const maxValue = queryObject[maxKey];

  const [localValues, setLocalValues] = useState({
    min: minValue,
    max: maxValue,
  });

  const { min: minLocal, max: maxLocal } = localValues;

  useEffect(() => {
    setLocalValues({
      min: minValue,
      max: maxValue,
    });
  }, [minValue, maxValue]);

  function submitRangeFilter() {
    const { page, ...restQueryObject } = queryObject;
    const newQuery = {
      ...restQueryObject,
      [minKey]: minLocal,
      [maxKey]: maxLocal,
    };

    if (!newQuery[minKey]) {
      delete newQuery[minKey];
    }

    if (!newQuery[maxKey]) {
      delete newQuery[maxKey];
    }

    router.push(
      {
        pathname: "/autosusados",
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  }

  function setFilterValue(e) {
    const value = e.target.value;
    setLocalValues({
      ...localValues,
      [e.target.name]: value,
    });
  }

  return {
    min: minValue,
    max: maxValue,
    setFilterValue,
    submitRangeFilter,
  };
}
