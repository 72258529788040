import { useRouter } from "next/router";
import { CURRENCY_KEYS } from "../utils/constants";
import { classNames } from "../utils/formatters";

export default function CurrencyFilter() {
  const router = useRouter();
  const queryObject = router.query;
  const { currency } = queryObject;

  function setCurrency(e) {
    e.preventDefault();

    const { priceMin, priceMax, ...restQueryObject } = queryObject;

    router.push(
      {
        pathname: "/autosusados",
        query: { ...restQueryObject, currency: e.target.id },
      },
      undefined,
      { shallow: true }
    );
  }

  return (
    <span className="relative z-0 inline-flex rounded-md shadow-sm">
      <button
        type="button"
        id={CURRENCY_KEYS.CRC}
        onClick={setCurrency}
        className={classNames(
          currency === CURRENCY_KEYS.CRC || !currency
            ? "text-orange-500 bg-gray-50"
            : "text-gray-500 bg-white",
          "relative inline-flex items-center rounded-l-md border border-gray-300 px-2 py-1 text-sm font-medium  hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500"
        )}
      >
        ₡
      </button>
      <button
        type="button"
        id={CURRENCY_KEYS.USD}
        onClick={setCurrency}
        className={classNames(
          currency === CURRENCY_KEYS.USD
            ? "text-orange-500 bg-gray-50"
            : "text-gray-500 bg-white",
          "relative inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-2 py-1 text-sm font-medium  hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500"
        )}
      >
        $
      </button>
    </span>
  );
}
