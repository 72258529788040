import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "../utils/formatters";

export default function FilterDropdown({ label, filters, setFilter }) {
  const hasActiveOption = filters.options.some((option) => option.checked);
  return (
    <Menu
      as="div"
      className="relative text-left z-20 mr-3 mt-2 hidden sm:inline-block"
    >
      <div>
        <Menu.Button
          className={classNames(
            "inline-flex justify-center w-full rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 ",
            hasActiveOption
              ? "ring-2 ring-offset-2 ring-offset-gray-100 ring-orange-500"
              : ""
          )}
        >
          {label}
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-4 px-3">
            {filters.options.map((option, optionIdx) => (
              <Menu.Item key={option.value}>
                <div className="flex items-center">
                  <input
                    id={`filter-mobile-${filters.id}-${optionIdx}`}
                    name={filters.id}
                    defaultValue={option.value}
                    type="checkbox"
                    defaultChecked={option.checked}
                    className="h-4 w-4 border-gray-300 rounded text-orange-600 focus:ring-orange-500 text-input"
                    onClick={setFilter}
                  />
                  <label
                    htmlFor={`filter-mobile-${filters.id}-${optionIdx}`}
                    className="ml-3 min-w-0 flex-1 text-gray-500"
                  >
                    {option.label}
                  </label>
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
