import { useRouter } from "next/router";
import { HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import { useGetUserFavoriteSearchesQuery } from "../graphql/generated/graphql";
import useFavoriteSearchMutations from "../hooks/useFavoriteSearchMutations";
import { generateQueryString } from "../utils/favoriteSearches";
import Spinner from "./Spinner";
import { classNames } from "../utils/formatters";
import { useSession } from "next-auth/react";

export default function FavoriteSearchButton({ queryObject }) {
  const router = useRouter();
  const { data: sessionData, status: sessionStatus } = useSession();
  const { user: currentUser } = sessionData || {};
  const currentUserId = currentUser?.id;

  const {
    data: userFavoriteSearchesData,
    loading: loadingUserFavoriteSearches,
  } = useGetUserFavoriteSearchesQuery({
    skip: !currentUserId,
  });
  const { getUserFavoriteSearches: favoriteSearches } =
    userFavoriteSearchesData || {};

  const {
    addFavoriteSearch,
    removeFavoriteSearch,
    savingFavoriteSearch,
    deletingFavoriteSearch,
  } = useFavoriteSearchMutations();

  const savedQuery = favoriteSearches?.find(
    (s) => s.query === generateQueryString(queryObject)
  );

  function deleteFavoriteSearch() {
    removeFavoriteSearch(savedQuery._id);
  }

  function handleClick() {
    if (sessionStatus === "unauthenticated") {
      router.push("/auth/signin");
      return;
    }
    if (savedQuery) {
      deleteFavoriteSearch();
    } else {
      addFavoriteSearch(queryObject);
    }
  }

  const FavoriteSearchIcon = savedQuery ? HeartIconSolid : HeartIcon;

  return (
    <>
      {Object.values(queryObject)?.length ? (
        <div className="flex items-center mt-1">
          {savingFavoriteSearch ||
          deletingFavoriteSearch ||
          loadingUserFavoriteSearches ? (
            <Spinner className="h-5 w-5" />
          ) : (
            <FavoriteSearchIcon
              className={classNames(
                savedQuery ? "text-red-500" : "text-gray-900",
                "h-5 w-5 flex-shrink-0"
              )}
              aria-hidden="true"
            />
          )}
          <button
            className="ml-2 text-sm font-medium text-orange-500 hover:text-orange-400 cursor-pointer disabled:opacity-50"
            onClick={handleClick}
            disabled={
              savingFavoriteSearch ||
              deletingFavoriteSearch ||
              loadingUserFavoriteSearches
            }
          >
            {savedQuery ? "Eliminar" : "Guardar como"} búsqueda favorita
          </button>
        </div>
      ) : null}
    </>
  );
}
