import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../utils/formatters";
import useRangeFilter from "../hooks/useRangeFilter";

export default function YearFilterDropdown() {
  const { min, max, setFilterValue, submitRangeFilter } = useRangeFilter([
    "yearMin",
    "yearMax",
  ]);

  const hasActiveOption = min || max;

  return (
    <Menu
      as="div"
      className="relative text-left z-20 mr-3 mt-2 hidden sm:inline-block"
    >
      <div>
        <Menu.Button
          className={classNames(
            "inline-flex justify-center w-full rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 ",
            hasActiveOption
              ? "ring-2 ring-offset-2 ring-offset-gray-100 ring-orange-500"
              : ""
          )}
        >
          Año
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        beforeLeave={submitRangeFilter}
      >
        <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-4 px-3">
            <div className="">
              <label
                htmlFor="yearMin"
                className="min-w-0 flex-1 text-gray-500 text-sm"
              >
                Min.
              </label>
              <input
                id="yearMin"
                name="min"
                defaultValue={min}
                type="number"
                className=" border-gray-300 rounded focus:ring-orange-500 focus:border-orange-500 text-input"
                onChange={setFilterValue}
              />
            </div>
            <div className="">
              <label
                htmlFor="yearMax"
                className="min-w-0 flex-1 text-gray-500 text-sm"
              >
                Max.
              </label>
              <input
                id="yearMax"
                name="max"
                defaultValue={max}
                type="number"
                className=" border-gray-300 rounded focus:ring-orange-500 focus:border-orange-500 text-input"
                onChange={setFilterValue}
              />
            </div>

            <Menu.Item>
              <button className="border-orange-500  rounded-md text-white bg-orange-500 hover:bg-orange-600 hover:border-orange-600 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500 font-medium px-4 py-2 mt-2 w-full inline-flex items-center justify-center space-x-2">
                <MagnifyingGlassIcon
                  className={classNames("text-white h-5 w-5")}
                  aria-hidden="true"
                />
                <span className="inline">Buscar</span>
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
