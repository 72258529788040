import { BellIcon } from "@heroicons/react/24/outline";
import { BellSlashIcon as BellIconSolid } from "@heroicons/react/24/solid";
import { useGetUserFavoriteSearchesQuery } from "../graphql/generated/graphql";
import useFavoriteSearchMutations from "../hooks/useFavoriteSearchMutations";
import { generateQueryString } from "../utils/favoriteSearches";
import Spinner from "./Spinner";
import { classNames } from "../utils/formatters";
import { useSession } from "next-auth/react";

export default function FavoriteSearchNotifyButton({ queryObject }) {
  const { data: sessionData } = useSession();
  const { user: currentUser } = sessionData || {};
  const currentUserId = currentUser?.id;

  const {
    data: userFavoriteSearchesData,
    loading: loadingUserFavoriteSearches,
  } = useGetUserFavoriteSearchesQuery({
    skip: !currentUserId,
  });
  const { getUserFavoriteSearches: favoriteSearches } =
    userFavoriteSearchesData || {};

  const { updateFavoriteSearch, updatingFavoriteSearch } =
    useFavoriteSearchMutations();

  const savedQuery = favoriteSearches?.find(
    (s) => s.query === generateQueryString(queryObject)
  );
  const notify = savedQuery?.notify;

  function handleClick() {
    updateFavoriteSearch(savedQuery._id, !savedQuery.notify);
  }

  const FavoriteSearchIcon = notify ? BellIconSolid : BellIcon;

  if (!savedQuery) {
    return null;
  }

  return (
    <>
      {Object.values(queryObject)?.length ? (
        <div className="flex items-center mt-1">
          {updatingFavoriteSearch || loadingUserFavoriteSearches ? (
            <Spinner className="h-5 w-5" />
          ) : (
            <FavoriteSearchIcon
              className={classNames(
                notify ? "text-blue-500" : "text-gray-900",
                "h-5 w-5 flex-shrink-0"
              )}
              aria-hidden="true"
            />
          )}
          <button
            className="ml-2 text-sm font-medium text-orange-500 hover:text-orange-400 cursor-pointer disabled:opacity-50"
            onClick={handleClick}
            disabled={updatingFavoriteSearch || loadingUserFavoriteSearches}
          >
            {notify
              ? "Silenciar notificaciones para esta búsqueda"
              : "Activar notificaciones para esta búsqueda"}
          </button>
        </div>
      ) : null}
    </>
  );
}
