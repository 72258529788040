import CollectionBanner from "./Banner";
import { classNames } from "../../utils/formatters";
import { MOVI_NUMBER_FORMATED, WHATSAPP_HREF } from "../../utils/constants";
import { BsWhatsapp } from "react-icons/bs";
import CollectionSubscriptionForm from "./SubscriptionForm";

export default function CollectionSubscriptionSection({
  vehicleId,
  className,
}: {
  vehicleId: string;
  className?: string;
}) {
  const WhatsappSection = ({ className }: { className?: string }) => (
    <div
      className={classNames(
        "grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8",
        className
      )}
    >
      <dl className="space-y-1 text-base leading-6 text-gray-600">
        <div>
          <dt className="sr-only">Email</dt>
          <dd>
            <a
              className="font-semibold text-orange-500 hover:text-orange-400"
              href="mailto:collaborate@example.com"
            >
              contacto@moviautos.com
            </a>
          </dd>
        </div>
        <div className="mt-1 text-white">
          <dt className="sr-only">Teléfono</dt>
          <dd>{MOVI_NUMBER_FORMATED}</dd>
        </div>
        <div className="mt-1">
          <dt className="sr-only">Whatsapp</dt>
          <a
            href={WHATSAPP_HREF}
            target="_blank"
            rel="noreferrer"
            className="font-semibold text-orange-500 flex gap-x-1 items-center hover:text-orange-400"
          >
            <BsWhatsapp className="h-5 w-5 pt-0.5 -translate-y-0.5" />
            Whatsapp
          </a>
        </div>
      </dl>
    </div>
  );

  return (
    <div className={classNames("bg-neutral-900 py-16", className)}>
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8">
        <div className="max-w-xl text-2xl font-bold tracking-tight text-white sm:text-3xl lg:col-span-7">
          <h2 className="inline sm:block lg:inline xl:block">
            {vehicleId
              ? "¿Desea más información acerca de este vehículo?"
              : "¿Desea comprar o vender un auto semi-nuevo o usado?"}
          </h2>{" "}
          <p className="inline sm:block lg:inline xl:block">
            Envíenos su información de contacto y le contactaremos pronto.
          </p>
          <WhatsappSection className="hidden md:block mt-4" />
        </div>
        <CollectionSubscriptionForm vehicleId={vehicleId} />
        <WhatsappSection className="block md:hidden" />
        <div className="lg:col-span-12">
          <CollectionBanner dark />
        </div>
      </div>
    </div>
  );
}
