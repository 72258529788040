/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
  */
import { Fragment, useState } from "react";
import { useRouter } from "next/router";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/solid";
import Layout from "../../components/Layout";
import Grid from "../../components/Grid";
import {
  CATEGORIES,
  CONDITIONS,
  DRIVETRAINS,
  FUELS,
  PROVINCES,
  TIER_KEYS,
  TRANSMISSIONS,
  getSlugMetadata,
} from "../../utils/constants";
import {
  QueryGetVehiclesArgs,
  useGetVehiclesQuery,
  User,
} from "../../graphql/generated/graphql";
import FilterDropdown from "../../components/FilterDropdown";
import SortDropdown from "../../components/SortDropdown";
import ListPagination from "../../components/ListPagination";
import PriceFilterDropdown from "../../components/PriceFilterDropdown";
import SearchField from "../../components/SearchField";
import PriceFilter from "../../components/PriceFilter";
import YearFilter from "../../components/YearFilter";
import YearFilterDropdown from "../../components/YearFilterDropdown";
import { useSession } from "next-auth/react";
import ListUserHeader from "../../components/ListUserHeader";
import StoreBanner from "../../components/StoreBanner";
import FavoriteSearchButton from "../../components/FavoriteSearchButton";
import FavoriteSearchNotifyButton from "../../components/FavoriteSearchNotifyButton";
import SEO from "../../components/SEO";
import Banners from "../../components/Banners";
import CollectionSubscriptionSection from "../../components/collection/SubscriptionSection";
import { capitalizeAllWords } from "../../utils/formatters";

type TFilterOption = {
  value: string;
  label: string;
  checked: boolean;
};

type TFilter = {
  id: string;
  name: string;
  options: TFilterOption[];
};

const categoryFilters = {
  id: "category",
  name: "Estilo",
  options: Object.values(CATEGORIES).map((category) => ({
    value: category.id,
    label: category.label,
    checked: false,
  })),
};

const conditionFilters = {
  id: "condition",
  name: "Condición",
  options: Object.values(CONDITIONS).map((condition) => ({
    value: condition.id,
    label: condition.label,
    checked: false,
  })),
};

const fuelFilters = {
  id: "fuel",
  name: "Combustible",
  options: Object.values(FUELS).map((condition) => ({
    value: condition.id,
    label: condition.label,
    checked: false,
  })),
};

const transmissionFilters = {
  id: "transmission",
  name: "Transmisión",
  options: Object.values(TRANSMISSIONS).map((condition) => ({
    value: condition.id,
    label: condition.label,
    checked: false,
  })),
};

const drivetrainFilters = {
  id: "drivetrain",
  name: "Tracción",
  options: Object.values(DRIVETRAINS).map((drivetrain) => ({
    value: drivetrain.id,
    label: drivetrain.label,
    checked: false,
  })),
};

const locationFilters = {
  id: "province",
  name: "Ubicación",
  options: Object.values(PROVINCES).map((province) => ({
    value: province.id,
    label: province.label,
    checked: false,
  })),
};

const initialFilters = {
  [categoryFilters.id]: categoryFilters,
  [conditionFilters.id]: conditionFilters,
  [fuelFilters.id]: fuelFilters,
  [transmissionFilters.id]: transmissionFilters,
  [drivetrainFilters.id]: drivetrainFilters,
  [locationFilters.id]: locationFilters,
};

const allowedFilters = [
  "username",
  "category",
  "condition",
  "transmission",
  "province",
  "priceMin",
  "priceMax",
  "currency",
  "drivetrain",
  "fuel",
];

const buildFilters = (qsFilters: TQueryStringFilters) => {
  Object.keys(initialFilters).forEach((filterId) => {
    const qsFilter = qsFilters[filterId];
    const filterOptions = initialFilters[filterId].options.map((opt) => ({
      ...opt,
    }));
    filterOptions.forEach((option: TFilterOption) => {
      if (qsFilter && qsFilter.split(",").includes(option.value)) {
        option.checked = true;
      } else {
        option.checked = false;
      }
    });

    initialFilters[filterId] = {
      ...initialFilters[filterId],
      options: filterOptions,
    };
  });

  return initialFilters;
};

function getVariables(queryObject, watchedUser: User): QueryGetVehiclesArgs {
  const {
    slug,
    // username,
    search,
    page,
    priceMin,
    priceMax,
    currency,
    yearMin,
    yearMax,
    sort,
    model,
    gclid, // this is to ignore google adds query param
    ...filters
  } = queryObject;

  const pageNumber = page ? Number(page) : 1;
  const variables: QueryGetVehiclesArgs = {
    page: pageNumber,
    filters: {},
  };

  Object.keys(filters)
    .filter((filterId) => allowedFilters.includes(filterId))
    .forEach((filterId) => {
      variables.filters[filterId] = filters[filterId].split(",");
    });

  if (watchedUser) {
    variables.filters.username = slug;
  }

  const slugMetadata = getSlugMetadata(slug);

  if (slugMetadata) {
    if (slugMetadata.type === "searchText") {
      variables.searchText = slugMetadata.filterValue as string;
    } else {
      variables.filters[slugMetadata.type] = slugMetadata.filterValue;
    }
  }

  if (model) {
    variables.filters.model = model;
  }

  if (sort) {
    variables.sort = sort;
  }

  if (priceMin) {
    variables.filters.priceMin = Number(priceMin);
  }

  if (priceMax) {
    variables.filters.priceMax = Number(priceMax);
  }

  variables.filters.currency = currency;

  if (yearMin) {
    variables.filters.yearMin = Number(yearMin);
  }

  if (yearMax) {
    variables.filters.yearMax = Number(yearMax);
  }

  if (search) {
    variables.searchText = search;
  }

  return variables;
}

const getSEOTitle = (username?: string, slugMetadata?: SlugMetadata) => {
  if (username && !slugMetadata) {
    return `Autos vendidos por ${capitalizeAllWords(username as string)}`;
  } else if (slugMetadata) {
    return slugMetadata.seo.title;
  }

  return "Listado de autos usados en Costa Rica";
};

const getSEODescription = (username, slugMetadata) => {
  if (username && !slugMetadata) {
    return `Página de ventas de ${capitalizeAllWords(username as string)}`;
  } else if (slugMetadata) {
    return slugMetadata.seo.description;
  }

  return "Las mejores ofertas de autos usados en Costa Rica";
};

export default function List({
  query,
  watchedUser,
  slug,
}: {
  query: TQueryString;
  watchedUser: User;
  slug?: string;
}) {
  const { data: sessionData } = useSession();
  const { user: currentUser } = sessionData || {};
  const currentUserId = currentUser?.id;
  const isCurrentUser = currentUserId && currentUserId === watchedUser?._id;

  const slugMetadata = getSlugMetadata(slug);

  const router = useRouter();
  const queryObject = router.query || query;

  const { username, search, page, sort, ...restFilters } = queryObject;

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const filters = buildFilters(restFilters);

  const variables = getVariables(queryObject, watchedUser);

  const { data, loading } = useGetVehiclesQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });

  const { getVehicles } = data || {};
  const { data: vehicles, meta } = getVehicles || {};
  const { total, pageSize } = meta || {};

  function setSort(e) {
    const sort = e.target.name;
    const { page, ...restQueryObject } = queryObject;

    const newQuery: TQueryString = {
      ...restQueryObject,
      sort,
    };

    router.push(
      {
        pathname: "/autosusados",
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  }

  function setFilter(e) {
    const filterId = e.target.name;
    const value = e.target.value;
    const { page, ...restQueryObject } = queryObject;
    const filterArray = restQueryObject[filterId]
      ? (restQueryObject[filterId] as string).split(",")
      : [];

    let newFilterArray = [];
    if (filterArray.includes(value)) {
      newFilterArray = filterArray.filter((f) => f !== value);
    } else {
      newFilterArray = [...filterArray, value];
    }

    const newQuery: TQueryString = {
      ...restQueryObject,
    };

    if (newFilterArray.length) {
      newQuery[filterId] = newFilterArray.join(",");
    } else {
      delete newQuery[filterId];
    }

    router.push(
      {
        pathname: watchedUser ? `/${slug}` : "/autosusados",
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  }

  function clearFilters() {
    router.push(
      {
        pathname: watchedUser ? `/${slug}` : "/autosusados",
        query: {},
      },
      undefined,
      { shallow: true }
    );
  }

  return (
    <div className="bg-white sm:pt-4">
      <SEO
        currentURL={`${process.env.NEXT_PUBLIC_ROOT_URL}/autosusados`}
        title={getSEOTitle(watchedUser?.username, slugMetadata)}
        description={getSEODescription(watchedUser?.username, slugMetadata)}
        siteTitle="Movi Autos"
      />
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                  <div className="px-4 flex items-center justify-between">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filtros
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <div className="mt-4 border-t border-gray-200">
                    <div className="px-4 py-2">
                      <SearchField
                        sidebar
                        pathname={slug ? `/${slug}` : null}
                      />
                      <PriceFilter />
                      <YearFilter />
                    </div>
                    {Object.values(filters).map((section) => (
                      <div
                        key={section.id}
                        className="border-t border-gray-200 px-4 py-4"
                      >
                        <h3 className="font-medium text-gray-900">
                          {section.name}
                        </h3>
                        <div className="pt-3">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                id={`filter-mobile-${section.id}-${optionIdx}`}
                                name={section.id}
                                defaultValue={option.value}
                                type="checkbox"
                                defaultChecked={option.checked}
                                className="h-4 w-4 border-gray-300 rounded text-orange-600 focus:ring-orange-500 text-input"
                                onClick={setFilter}
                              />
                              <label
                                htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                className="ml-3 min-w-0 flex-1 text-gray-500"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {watchedUser?.company?.banner ? (
          <div className="max-w-7xl mx-auto sm:px-4 mb-4">
            {" "}
            <StoreBanner src={watchedUser?.company?.banner} />
          </div>
        ) : null}

        {!watchedUser?.company?.banner ? <Banners /> : null}
        <main className="max-w-7xl mx-auto px-4">
          {watchedUser ? <ListUserHeader user={watchedUser} /> : null}

          <div className="py-6">
            <div className="relative z-10 flex items-center justify-between flex-wrap">
              <div>
                {/* {search ? (
                  <>
                    <h1 className="text-2xl">{getSEOTitle(watchedUser?.username, slugMetadata)}</h1>
                    <span className="text-lg">
                      {loading ? (
                        "Buscando "
                      ) : (
                        <span>
                          {total || 0} resultados {search ? "para" : ""}{" "}
                        </span>
                      )}

                      <span className="font-bold">&quot;{search}&quot;</span>
                    </span>
                  </>
                ) : (
                  <h1 className="text-2xl">
                    {getSEOTitle(watchedUser?.username, slugMetadata)}
                  </h1>
                )} */}
                <h1 className="text-2xl">
                  {getSEOTitle(watchedUser?.username, slugMetadata)}
                </h1>
                <h2 className="text-lg text-gray-700">
                  {loading ? (
                    "Buscando "
                  ) : (
                    <span>
                      {total || 0} resultados{" "}
                      {search || slugMetadata?.id ? "para" : ""}{" "}
                    </span>
                  )}

                  {search || slugMetadata?.id ? (
                    <span className="font-bold">
                      &quot;{search || slugMetadata?.id}&quot;
                    </span>
                  ) : null}
                </h2>
                <div className="flex flex-col sm:flex-row gap-x-3 gap-y-2">
                  <FavoriteSearchButton queryObject={queryObject} />
                  <FavoriteSearchNotifyButton queryObject={queryObject} />
                </div>
              </div>
              <SortDropdown
                className="hidden sm:inline-block"
                sort={sort}
                setSort={setSort}
              />
            </div>

            <div className="mt-3 flex items-center flex-wrap justify-between sm:justify-start">
              <FilterDropdown
                label="Transmisión"
                filters={filters.transmission}
                setFilter={setFilter}
              />
              <FilterDropdown
                label="Ubicación"
                filters={filters.province}
                setFilter={setFilter}
              />
              {/* <FilterDropdown
                label="Combustible"
                filters={filters.fuel}
                setFilter={setFilter}
              /> */}
              <FilterDropdown
                label="Estilo"
                filters={filters.category}
                setFilter={setFilter}
              />
              <FilterDropdown
                label="Condición"
                filters={filters.condition}
                setFilter={setFilter}
              />
              <PriceFilterDropdown />
              <YearFilterDropdown />
              <FilterDropdown
                label="Tracción"
                filters={filters.drivetrain}
                setFilter={setFilter}
              />
              <div>
                <button
                  type="button"
                  className="mt-2 mr-2 inline-flex justify-center rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-orange-500"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <AdjustmentsVerticalIcon
                    className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="hidden sm:inline">Todos los filtros</span>
                  <span className="inline sm:hidden">Filtros</span>
                </button>
                <button
                  type="button"
                  className="mr-2 justify-center text-sm font-medium border border-red-400 text-red-400 hover:text-red-500 hover:border-red-500 shadow-sm mt-2 inline-flex rounded-full px-4 py-2"
                  onClick={clearFilters}
                >
                  <XMarkIcon
                    className="flex-shrink-0 mr-1 h-5 w-5 text-red-400 group-hover:text-red-500"
                    aria-hidden="true"
                  />
                  <span>Limpiar filtros</span>
                </button>
              </div>
              <SortDropdown
                className="sm:hidden inline-block mt-2"
                sort={sort}
                setSort={setSort}
              />
            </div>
          </div>

          <section aria-labelledby="results-heading">
            <h2 id="results-heading" className="sr-only">
              Resultados
            </h2>

            <div>
              <ListPagination
                loading={loading}
                page={page ? Number(page) : 1}
                // setPage={setPage}
                total={total}
                pageSize={pageSize}
              />
              <Grid
                items={vehicles}
                loading={loading}
                isCurrentUser={isCurrentUser}
                noPadding
              />
              <ListPagination
                loading={loading}
                page={page ? Number(page) : 1}
                // setPage={setPage}
                total={total}
                pageSize={pageSize}
              />
            </div>
          </section>
          {/* <HorizontalAdBanner /> */}
          <div className="mt-10">
            {slug === TIER_KEYS.COLLECTION ? (
              <CollectionSubscriptionSection
                vehicleId={null}
                className="rounded-lg"
              />
            ) : (
              <Banners />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

List.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};
