import { useState } from "react";
import { User } from "../graphql/generated/graphql";
import ContactModal from "./ContactBusinessModal";
import { event } from "../lib/gtag";

type TProps = {
  className?: string;
  label?: string;
  expired?: boolean;
  seller: User;
};

export default function ContactButton({
  className,
  label,
  expired,
  seller,
}: TProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const disabled = expired;

  function openModal() {
    event({
      action: "Contact",
      category: "Modal",
      label: seller.username,
    });
    setIsModalOpen(true);
  }

  let displayLabel = expired ? "Anuncio expirado" : "Contactar vendedor";

  if (label) {
    displayLabel = label;
  }

  return (
    <>
      <button
        onClick={openModal}
        disabled={disabled}
        className={
          className
            ? className
            : "mt-2 w-full bg-orange-500 border border-transparent rounded-md py-3 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:bg-gray-200"
        }
      >
        {displayLabel}
      </button>
      <ContactModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        seller={seller}
      />
    </>
  );
}
